import {
	TextField,
	SelectField,
	TextFieldTypes,
	Button,
	ExpansionList,
	ExpansionPanel,
	FontIcon,
	Card,
} from 'react-md'
import { useEffect, useState } from 'react'
import { ListValue } from 'react-md/lib/SelectFields/SelectField'
import { FormattedMessage, useIntl } from 'react-intl'
import type { Contract, PaymentProductLinkUpdate } from './types'
import DatePickerISO from 'ui/components/DatePickerISO'
import { ContractPaymentProductHeaders } from './constants'
import { billingBasisMessage } from '../utils/messages'
import { PaymentProduct } from '../PaymentProducts/types'
import type { SelectOptions } from '../types'
import useDialogs from 'ui/components/dialogs/useDialogs'
import { PaymentProductBillingBasis } from '../enum'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { removeLeadingZero } from '../utils/utils'
import type { Apartment } from 'types/apartment'

const Horizontal = styled.div`
	display: flex;
	gap: 16px;
	margin-bottom: 16px;
	min-width: 50px;
	margin-right: 2rem;
`

const FormDiv = styled.div`
	min-width: 50px;
	margin-right: 2rem;
	width: auto;
`
const DateDiv = styled.div`
	min-width: 50px;
	margin-right: 2rem;
	width: auto;
`

const PaymentProductCard = styled(Card)`
	border-radius: 8px;
	padding: 16px;
	margin: 0 auto 32px auto;
`

const CardTitle = styled.h2`
	margin: 16px 0 32px 0;
	font-size: 1.6rem;
`

const Section = styled.section`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: baseline;
`
const Actions = styled.div`
	display: flex;
	gap: 8px;
	flex-wrap: wrap;
	justify-content: flex-end;
`
interface Props {
	contract: Contract | undefined
	paymentProducts: PaymentProduct[]
	formData: Map<string, PaymentProductLinkUpdate>
	setFormData: React.Dispatch<
		React.SetStateAction<Map<string, PaymentProductLinkUpdate>>
	>
	selectedApartment: Apartment | undefined
}

const ContractPaymentProductLinkDialog = ({
	contract,
	paymentProducts,
	formData,
	setFormData,
	selectedApartment,
}: Props) => {
	const intl = useIntl()
	const [expanded, setExpanded] = useState<boolean>(false)
	const [editQuantity, setEditQuantity] = useState<string>('')
	const { alert, confirm } = useDialogs()
	const [showInactivePP, setShowInactivePP] = useState<boolean>(false)

	useEffect(() => {
		if (
			contract &&
			Array.isArray(contract.contractPaymentProductLinks) &&
			contract.contractPaymentProductLinks.length > 0
		) {
			const updatedData = new Map(formData)
			contract.contractPaymentProductLinks.forEach((link) => {
				if (!updatedData.get(link.uuid)) {
					const paymentProduct = paymentProducts.find(
						(p) => p.uuid === link.paymentProductUUID,
					)
					if (paymentProduct) {
						updatedData.set(link.uuid, {
							paymentProduct: paymentProduct,
							linkUUID: link.uuid,
							updated: false,
							quantity: link.quantity,
							quantityLock: link.quantityLock,
							startDate: link.startDate,
							endDate: link.endDate,
						})
					}
				}
			})
			setFormData(updatedData)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contract, paymentProducts, setFormData])

	const handleInputChange = (
		value: string | number,
		field: string,
		uuid: string,
		type: string,
	) => {
		setFormData((prevData) => {
			const updatedData = new Map(prevData)
			const link = updatedData.get(uuid)
			const val =
				type === 'number'
					? removeLeadingZero(Math.max(0, Number(value)))
					: value
			if (link && link.create) {
				const updatedLink = {
					...link,
					[field]: val,
				}
				updatedData.set(uuid, updatedLink)
			} else if (link) {
				const updatedLink = {
					...link,
					[field]: val,
					updated: true,
				}
				updatedData.set(uuid, updatedLink)
			}
			return updatedData
		})
	}

	const getQuantityByBillingBasis = (
		basis: PaymentProductBillingBasis,
	): number | undefined => {
		if (basis === PaymentProductBillingBasis.AREA) {
			return selectedApartment?.squaresAmount
		} else if (basis === PaymentProductBillingBasis.AREA_ADJUSTED) {
			return selectedApartment?.weightedSquaresAmount
		} else if (basis === PaymentProductBillingBasis.SHARE_COUNT) {
			return selectedApartment?.shareCount
		} else return undefined
	}

	const handleSelectChange = async (value: ListValue, field: string) => {
		if (field === 'paymentProductUUID' && paymentProducts) {
			const paymentProduct = paymentProducts.find((p) => p.uuid === value)
			if (paymentProduct) {
				const quantity = getQuantityByBillingBasis(paymentProduct.billingBasis)
				if (quantity) {
					setFormData((prevData) => {
						const updatedData = new Map(prevData)
						updatedData.set(uuidv4(), {
							paymentProduct: paymentProduct,
							create: true,
							quantity: quantity,
							quantityLock: true,
							startDate: null,
							endDate: null,
						})
						return updatedData
					})
				} else {
					const alertMsg = intl.formatMessage({
						defaultMessage:
							'Maksulajille ei saatu haettua määrää automaattisesti',
						description:
							'Alert that something went wrong getting the quantity with billing basis',
					})
					alert(alertMsg)
					const uuid = uuidv4()
					setEditQuantity(uuid)
					setFormData((prevData) => {
						const updatedData = new Map(prevData)
						updatedData.set(uuid, {
							paymentProduct: paymentProduct,
							create: true,
							quantity: 0,
							quantityLock: false,
							startDate: null,
							endDate: null,
						})
						return updatedData
					})
				}
			}
		}
	}

	const handleDeselectLink = async (uuid: string) => {
		const confirmMsg = intl.formatMessage({
			defaultMessage: 'Haluatko varmasti poistaa maksulajin sopimukselta?',
			description:
				'Cofirmation message for deleting payment product from contract',
		})
		if (await confirm(confirmMsg)) {
			setFormData((prevData) => {
				const updatedData = new Map(prevData)
				const link = updatedData.get(uuid)
				if (link && link.linkUUID) {
					const updatedLink = { ...link, deleted: true, updated: false }
					updatedData.set(uuid, updatedLink)
				} else {
					updatedData.delete(uuid)
				}
				return updatedData
			})
		}
	}
	const handleRecoverLink = async (uuid: string) => {
		const confirmMsg = intl.formatMessage({
			defaultMessage:
				'Haluatko varmasti perua maksulajin poiston sopimukselta?',
			description:
				'Cofirmation message for recover deleted payment product from contract',
		})
		if (await confirm(confirmMsg)) {
			setFormData((prevData) => {
				const updatedData = new Map(prevData)
				const link = updatedData.get(uuid)
				if (link && link.linkUUID) {
					const updatedLink = { ...link, deleted: false, updated: true }
					updatedData.set(uuid, updatedLink)
				} else {
					updatedData.delete(uuid)
				}
				return updatedData
			})
		}
	}

	let paymentProductOptions: SelectOptions[] = []
	if (paymentProducts && paymentProducts.length > 0) {
		paymentProductOptions = paymentProducts.map((p: PaymentProduct) => {
			return {
				label: p.name,
				value: p.uuid,
			}
		})
	}

	const getValue = (
		link: PaymentProductLinkUpdate,
		key: string,
		type: string,
	) => {
		let currentDate = new Date()

		let price = link.paymentProduct.prices.find((p) => {
			let startDate = new Date(p.startDate)
			let endDate = p.endDate ? new Date(p.endDate) : null

			return currentDate >= startDate && (!endDate || currentDate <= endDate)
		})
		if (type === 'paymentProduct') {
			return link.paymentProduct[key]
		} else if (key === 'price' || key === 'priceWithVat') {
			return price?.[key] || ''
		} else if (key === 'billingBasis') {
			return billingBasisMessage(intl, link.paymentProduct[key])
		} else if (key === 'billingAmount') {
			return price && link.quantity ? price?.price * link.quantity : 0
		} else if (key === 'billingAmountWithVat') {
			return price && link.quantity ? price?.priceWithVat * link.quantity : 0
		} else {
			return link[key] || ''
		}
	}

	const renderFormFields = (uuid: string, link: PaymentProductLinkUpdate) => {
		return ContractPaymentProductHeaders.map((header) => {
			const { show, key, title, type, required, editable } = header

			const value = getValue(link, key, type)

			if (type === 'date' && show) {
				return (
					<DateDiv key={key}>
						<DatePickerISO
							style={{ width: '100%' }}
							key={key}
							id={key}
							label={title(intl)}
							value={value}
							onChange={(value) => handleInputChange(value, key, uuid, type)}
							required={required}
						/>
					</DateDiv>
				)
			} else if (key === 'quantity') {
				const lockValue = getValue(link, 'quantityLock', type)
				return (
					<Horizontal key={key}>
						<TextField
							key={key}
							id={key}
							label={title(intl)}
							lineDirection="center"
							floating
							value={removeLeadingZero(value) || 0}
							disabled={lockValue || editQuantity !== uuid || !editable}
							onChange={(value) => handleInputChange(value, key, uuid, type)}
							required={required}
							type={(type as TextFieldTypes) || undefined}
							min={0}
						/>
						{!lockValue && (
							<Button
								flat
								style={{ margin: '16px 0px 0px 0px', minWidth: '32px' }}
								onClick={() => {
									if (editQuantity === uuid) {
										setEditQuantity('')
									} else {
										setEditQuantity(uuid)
									}
								}}
							>
								<FontIcon>edit</FontIcon>
							</Button>
						)}
					</Horizontal>
				)
			} else if (type === 'paymentProduct') {
				return (
					<FormDiv key={key}>
						<TextField
							key={key}
							id={key}
							label={title(intl)}
							lineDirection="center"
							floating
							value={value}
							disabled={true}
							onChange={(value) => handleInputChange(value, key, uuid, type)}
							required={required}
							type={'text'}
						/>
					</FormDiv>
				)
			} else {
				return (
					<FormDiv key={key}>
						<TextField
							key={key}
							id={key}
							label={title(intl)}
							lineDirection="center"
							floating
							value={value}
							disabled={!editable}
							onChange={(value) => handleInputChange(value, key, uuid, type)}
							required={required}
							type={(type as TextFieldTypes) || undefined}
						/>
					</FormDiv>
				)
			}
		})
	}

	const renderFormDatas = () => {
		return Array.from(formData.entries())
			.filter(([uuid, paymentProductLink]) => {
				if (showInactivePP || !paymentProductLink.endDate) return true
				return new Date(paymentProductLink.endDate).getTime() >= Date.now()
			})
			.sort(([aid, a], [bid, b]) => {
				if (a.deleted && !b.deleted) return 1
				if (!a.deleted && b.deleted) return -1
				if (a.deleted && b.deleted) return 0

				const aDate = a.startDate ? new Date(a.startDate).getTime() : null
				const bDate = b.startDate ? new Date(b.startDate).getTime() : null

				if (aDate === null && bDate === null) return 0
				if (aDate === null) return -1
				if (bDate === null) return 1

				return bDate - aDate
			})
			.map(([uuid, paymentProductLink]) => {
				const title = getValue(paymentProductLink, 'name', 'paymentProduct')
				return (
					<PaymentProductCard
						key={uuid}
						style={{
							background: paymentProductLink.deleted ? 'lightGrey' : '',
						}}
					>
						<CardTitle>{title}</CardTitle>
						<Section>{renderFormFields(uuid, paymentProductLink)}</Section>
						<Actions>
							{paymentProductLink.deleted ? (
								<Button icon onClick={() => handleRecoverLink(uuid)}>
									restore
								</Button>
							) : (
								<Button icon onClick={() => handleDeselectLink(uuid)}>
									delete
								</Button>
							)}
						</Actions>
					</PaymentProductCard>
				)
			})
	}

	const renderChildren = () => {
		return (
			<>
				<div
					className="margin-left--lg margin-right--lg"
					style={{ paddingBottom: 64 }}
				>
					<SelectField
						key="target"
						id="target"
						placeholder={intl.formatMessage({
							defaultMessage: 'Maksulajit',
							description: 'Placeholder of payment products',
						})}
						value={''}
						onChange={(value) =>
							handleSelectChange(value, 'paymentProductUUID')
						}
						menuItems={paymentProductOptions}
						position={SelectField.Positions.BELOW}
						simplifiedMenu={false}
						listHeightRestricted
						style={{
							width: '100%',
							background: '#fafafa',
							marginBottom: '10px',
						}}
					/>
					<div>
						<Button
							secondary
							style={{
								fontSize: '16px',
								display: 'flex',
								justifyItems: 'flex-end',
								paddingBottom: '10px',
							}}
							onClick={() => setShowInactivePP(!showInactivePP)}
						>
							{showInactivePP ? (
								<FormattedMessage
									defaultMessage="Piilota ei aktiiviset maksulajit"
									description="Hide inactive payment products"
								/>
							) : (
								<FormattedMessage
									defaultMessage="Näytä ei aktiiviset maksulajit"
									description="Show inactive payment products"
								/>
							)}
						</Button>
					</div>
					{renderFormDatas()}
				</div>
			</>
		)
	}

	return (
		<ExpansionList>
			<ExpansionPanel
				onExpandToggle={() => {
					if (selectedApartment) {
						setExpanded(!expanded)
					} else {
						const alertMsg = intl.formatMessage({
							defaultMessage: 'Valitse huoneisto ennen maksulajien valintaa',
							description:
								'Alert to select apartment before setting payment products',
						})
						setExpanded(false)
						alert(alertMsg)
					}
				}}
				label={
					<h4 style={{ margin: 0 }}>
						{intl.formatMessage({
							defaultMessage: 'Maksulajit',
							description: 'Title of payment products',
						})}
					</h4>
				}
				expanded={expanded}
				footer={null}
			>
				<div className="margin-top">{renderChildren()}</div>
			</ExpansionPanel>
		</ExpansionList>
	)
}

export default ContractPaymentProductLinkDialog
