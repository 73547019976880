import type {
	Contract,
	CreateContractPaymentProductLink,
	CreateContract,
	EditContractPaymentProductLink,
	PaymentProductLinkUpdate,
} from './types'
import ViiluFullPageDialog, { Action } from 'ui/components/ViiluFullPageDialog'
import { useState, useEffect, Fragment } from 'react'
import { useIntl } from 'react-intl'
import {
	TextField,
	TextFieldTypes,
	SelectField,
	CircularProgress,
	Button,
} from 'react-md'
import { ListValue } from 'react-md/lib/SelectFields/SelectField'
import { useDispatch } from 'react-redux'
import DatePickerISO from 'ui/components/DatePickerISO'
import useDialogs from 'ui/components/dialogs/useDialogs'
import YesNoSwitch from 'ui/components/YesNoSwitch'
import { GENERIC_ERROR_NOTIFICATION } from 'ui/messages'
import { createContractHeaders } from './constants'
import * as notifyActions from 'state/notifyActions'
import {
	_createContractWithLinks,
	_updateContractWithLinks,
} from 'state/contract-actions'
import useContract from './useContract'
import ContractPaymentProductLinkDialog from './ContractPaymentProductLinkDialog'
import { StandardDialogContent } from 'ui/StyledComponents/BasicComponents'
import { push } from 'connected-react-router'
import type { UseContractsResult } from './useContracts'
import type {
	SelectOptions,
	ApartmentSelectOptions,
	PartySelectOptions,
} from '../types'
import usePaymentProducts from '../PaymentProducts/usePaymentProducts'
import { UPDATE_INVOICES_REMINDER_MESSAGE } from '../utils/messages'
import { validateContractForm } from './validate'
import type { Apartment } from 'types/apartment'

type Props = {
	id?: string
	useContracts: UseContractsResult
	onHide: () => void
	selectedFinanceCompanyUUID: string
	companyOptions: SelectOptions[]
	payerOptions: PartySelectOptions[]
	apartmentOptions: ApartmentSelectOptions[]
	invoicingOptions: SelectOptions[]
	companyApartments: Apartment[]
}

const ContractDialog = ({
	id,
	useContracts,
	onHide,
	selectedFinanceCompanyUUID,
	companyOptions,
	payerOptions,
	apartmentOptions,
	invoicingOptions,
	companyApartments,
}: Props) => {
	const { refreshContracts } = useContracts
	const intl = useIntl()
	const dispatch = useDispatch()
	const { contract } = useContract(id)

	const initialFormData: CreateContract | Contract = {
		companyUUID: selectedFinanceCompanyUUID,
		apartmentUUID: '',
		apartmentIdentifier: '',
		partyUUID: '',
		secondaryPartyUUID: '',
		startDate: null,
		endDate: null,
		customInvoicing: false,
		referenceNumber: '',
		invoicingMethod: null,
		invoicingAddress: null,
	}

	const [linkFormData, setLinkFormData] = useState<
		Map<string, PaymentProductLinkUpdate>
	>(new Map())

	const [formData, setFormData] = useState<CreateContract | Contract>(
		initialFormData,
	)
	const [processing, setProcessing] = useState(false)
	const { alert } = useDialogs()
	const resetFormData = () => {
		setFormData(initialFormData)
		setLinkFormData(new Map())
	}

	const { data: paymentProducts } = usePaymentProducts(
		selectedFinanceCompanyUUID,
	)

	useEffect(() => {
		if (contract) {
			setFormData({
				uuid: contract.uuid,
				companyUUID: contract.companyUUID || selectedFinanceCompanyUUID,
				apartmentUUID: contract.apartmentUUID || '',
				apartmentIdentifier: contract.apartmentIdentifier || '',
				partyUUID: contract.partyUUID || '',
				secondaryPartyUUID: contract.secondaryPartyUUID || '',
				startDate: contract.startDate || null,
				endDate: contract.endDate || null,
				referenceNumber: contract.referenceNumber || '',
				customInvoicing: contract.customInvoicing || false,
				invoicingMethod: contract.invoicingMethod || null,
				invoicingAddress: contract.invoicingAddress || null,
			})
		}

		setFormData((prev) => ({
			...prev,
			companyUUID: selectedFinanceCompanyUUID,
		}))
	}, [contract, selectedFinanceCompanyUUID])

	const handleInputChange = (
		value: string | number | boolean,
		field: string,
	) => {
		setFormData((prevData) => ({
			...prevData,
			[field]: value,
		}))
	}

	const handleSelectChange = (value: ListValue, field: string) => {
		if (field === 'apartmentUUID') {
			const apartmentIdentifier = apartmentOptions.find(
				(opt) => opt.value === value,
			)?.identifier
			setFormData((prevData) => ({
				...prevData,
				[field]: value as string,
				apartmentIdentifier: apartmentIdentifier as string,
				partyUUID: '',
				secondaryPartyUUID: '',
			}))
		} else
			setFormData((prevData) => ({
				...prevData,
				[field]: value,
			}))
	}

	const hide = () => {
		resetFormData()
		onHide()
	}

	const getCreateLinkFormData = () => {
		return Array.from(linkFormData.entries()).map(([uuid, link]) => {
			return {
				paymentProductUUID: link.paymentProduct.uuid,
				quantity: link.quantity || 0,
				quantityLock: link.quantityLock || false,
				startDate: link.startDate || null,
				endDate: link.endDate || null,
			}
		})
	}

	const handleSave = async () => {
		const linkFormData: CreateContractPaymentProductLink[] =
			getCreateLinkFormData()

		const isValid = await validateContractForm(
			linkFormData,
			{ ...(formData as Contract) },
			alert,
			intl,
		)

		if (!isValid) {
			setProcessing(false)
			return
		}

		const result: any = await _createContractWithLinks(
			{ ...(formData as Contract) },
			linkFormData,
		)
		setProcessing(false)
		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Sopimus on lisätty.',
						description: 'Notification text of contract has been added',
					}),
			),
		)
		refreshContracts()
		hide()
		return true
	}

	const getUpdateLinkFormData = () => {
		return Array.from(linkFormData.entries())
			.filter(([uuid, link]) => {
				if (link.linkUUID) {
					return !(link.updated === false && link.deleted === false)
				}
				return true
			})
			.map(([uuid, link]) => {
				if (link.linkUUID) {
					return {
						contractUUID: id || null,
						paymentProductUUID: link.paymentProduct.uuid,
						linkUUID: link.linkUUID,
						create: false,
						updated: link.updated || false,
						deleted: link.deleted || false,
						quantity: link.quantity || 0,
						quantityLock: link.quantityLock || false,
						startDate: link.startDate || null,
						endDate: link.endDate || null,
					}
				} else {
					return {
						contractUUID: id || null,
						paymentProductUUID: link.paymentProduct.uuid,
						linkUUID: null,
						create: true,
						updated: false,
						deleted: false,
						quantity: link.quantity || 0,
						quantityLock: link.quantityLock || false,
						startDate: link.startDate || null,
						endDate: link.endDate || null,
					}
				}
			})
	}

	const handleUpdate = async () => {
		const linkFormData: EditContractPaymentProductLink[] =
			getUpdateLinkFormData()

		const isValid = await validateContractForm(
			linkFormData,
			{ ...(formData as Contract) },
			alert,
			intl,
		)

		if (!isValid) {
			setProcessing(false)
			return
		}

		const result: any = await _updateContractWithLinks(
			{
				...(formData as Contract),
			},
			linkFormData,
		)
		setProcessing(false)
		if (!result || result.ok === false) {
			dispatch(notifyActions.info('❌ ' + GENERIC_ERROR_NOTIFICATION(intl)))
			return false
		}

		dispatch(
			notifyActions.info(
				'✅ ' +
					intl.formatMessage({
						defaultMessage: 'Sopimus on lisätty.',
						description: 'Notification text of contract has been added',
					}),
			),
		)
		refreshContracts()
		hide()
		alert(UPDATE_INVOICES_REMINDER_MESSAGE(intl))
		return true
	}

	const onProcess = async () => {
		setProcessing(true)
		if ((formData as Contract).uuid) {
			return await handleUpdate()
		} else return await handleSave()
	}

	const renderFormFields = () => {
		return createContractHeaders.map((header) => {
			const { show, key, title, type, required, editable } = header
			if (show) {
				if (
					(key === 'invoicingAddress' || type === 'invoicingMethod') &&
					!formData.customInvoicing
				)
					return null
				else if (key === 'invoicingAddress') {
					if (formData.invoicingMethod === 'webInvoice')
						return (
							<TextField
								key={key}
								id={key}
								label={title(intl)}
								lineDirection="center"
								floating
								fullWidth
								value={formData[key] || ''}
								onChange={(value) => handleInputChange(value, key)}
								required={true}
								type={(type as TextFieldTypes) || undefined}
								disabled={false}
							/>
						)
					return null
				} else if (type === 'invoicingMethod') {
					return (
						<Fragment key={key}>
							<p className="text-subtle">
								{intl.formatMessage({
									defaultMessage: 'Laskutustapa',
									description: 'Title of invoicing method',
								})}
							</p>
							<SelectField
								id={key}
								key={key}
								placeholder={intl.formatMessage({
									defaultMessage: 'Laskutustapa',
									description: 'Placeholder of invoicing method',
								})}
								value={formData[key] || ''}
								onChange={(value) => handleSelectChange(value, key)}
								menuItems={invoicingOptions}
								position={SelectField.Positions.BELOW}
								simplifiedMenu={false}
								listHeightRestricted
								style={{
									width: '100%',
									background: '#fafafa',
									marginBottom: '10px',
								}}
							/>
						</Fragment>
					)
				} else if (key === 'referenceNumber') {
					return (
						<TextField
							key={key}
							id={key}
							label={
								<p>
									{title(intl)}{' '}
									{header.boldTitle && <b>{header.boldTitle(intl)}</b>}
								</p>
							}
							lineDirection="center"
							floating
							fullWidth
							value={formData[key] || ''}
							onChange={(value) => handleInputChange(value, key)}
							required={required}
							type={(type as TextFieldTypes) || undefined}
							disabled={!editable}
						/>
					)
				} else if (type === 'date') {
					return (
						<DatePickerISO
							style={{ width: '100%', paddingTop: '5px' }}
							key={key}
							id={key}
							label={title(intl)}
							value={formData[key]}
							onChange={(value) => handleInputChange(value, key)}
						/>
					)
				} else if (type === 'boolean') {
					return (
						<YesNoSwitch
							key={key}
							label={title(intl)}
							name={key}
							checked={formData[key]}
							onChange={(checked: boolean) => {
								handleInputChange(checked, key)
							}}
							style={{ marginTop: '10px' }}
						/>
					)
				} else {
					return (
						<TextField
							key={key}
							id={key}
							label={title(intl)}
							lineDirection="center"
							floating
							fullWidth
							value={formData[key] || ''}
							onChange={(value) => handleInputChange(value, key)}
							required={required}
							type={(type as TextFieldTypes) || undefined}
							disabled={!editable}
						/>
					)
				}
			}
			return null
		})
	}

	let secondaryPayerOptions = [
		{
			label: '',
			value: '',
			apartmentUUID: formData.apartmentUUID,
		},
		...payerOptions,
	]

	const renderPaymentProducts = () => {
		return (
			<ContractPaymentProductLinkDialog
				contract={contract}
				paymentProducts={paymentProducts}
				formData={linkFormData}
				setFormData={setLinkFormData}
				selectedApartment={companyApartments.find(
					(a) => a.uuid === formData.apartmentUUID,
				)}
			/>
		)
	}

	const renderChildren = () => {
		return (
			<div
				className="margin-left--lg margin-right--lg"
				style={{ paddingBottom: 64 }}
			>
				<p className="text-subtle">
					{intl.formatMessage({
						defaultMessage: 'Kohde',
						description: 'Title of company',
					})}
				</p>
				<SelectField
					id="company"
					placeholder={intl.formatMessage({
						defaultMessage: 'Kohde',
						description: 'Placeholder of company',
					})}
					value={formData.companyUUID}
					onChange={(value) => handleSelectChange(value, 'companyUUID')}
					menuItems={companyOptions}
					position={SelectField.Positions.BELOW}
					simplifiedMenu={false}
					listHeightRestricted
					disabled
					style={{
						width: '100%',
						background: '#fafafa',
						marginBottom: '10px',
					}}
				/>
				<p className="text-subtle">
					{intl.formatMessage({
						defaultMessage: 'Huoneisto',
						description: 'Title of apartment',
					})}
				</p>
				<SelectField
					id="apartment"
					placeholder={intl.formatMessage({
						defaultMessage: 'Huoneisto',
						description: 'Placeholder of apartment',
					})}
					value={formData.apartmentUUID}
					//TODO: Filter these according to available for selected party and empty out if the selected one isn't correct.
					//This should be done after making it possible to have multiple apartments via same party
					onChange={(value) => handleSelectChange(value, 'apartmentUUID')}
					menuItems={apartmentOptions}
					position={SelectField.Positions.BELOW}
					simplifiedMenu={false}
					listHeightRestricted
					style={{
						width: '100%',
						background: '#fafafa',
						marginBottom: '10px',
					}}
				/>

				<p className="text-subtle">
					{intl.formatMessage({
						defaultMessage: 'Maksaja',
						description: 'Title of payer',
					})}
				</p>
				<SelectField
					id="partyUUID"
					placeholder={intl.formatMessage({
						defaultMessage: 'Maksaja',
						description: 'Placeholder of payer',
					})}
					value={formData.partyUUID}
					onChange={(value) => handleSelectChange(value, 'partyUUID')}
					menuItems={payerOptions.filter(
						(p) =>
							p.value !== formData.secondaryPartyUUID &&
							(!formData.apartmentUUID ||
								p.apartmentUUID === formData.apartmentUUID),
					)}
					position={SelectField.Positions.BELOW}
					simplifiedMenu={false}
					listHeightRestricted
					style={{
						width: '100%',
						background: '#fafafa',
						marginBottom: '10px',
					}}
				/>
				<p className="text-subtle">
					{intl.formatMessage({
						defaultMessage: 'Toinen maksaja',
						description: 'Title of secondary payer',
					})}
				</p>
				<SelectField
					id="secondaryPartyUUID"
					placeholder={intl.formatMessage({
						defaultMessage: 'Toinen maksaja',
						description: 'Placeholder of secondary payer',
					})}
					value={formData.secondaryPartyUUID || ''}
					onChange={(value) => handleSelectChange(value, 'secondaryPartyUUID')}
					menuItems={secondaryPayerOptions.filter(
						(p) =>
							p.value !== formData.partyUUID &&
							(!formData.apartmentUUID ||
								p.apartmentUUID === formData.apartmentUUID),
					)}
					position={SelectField.Positions.BELOW}
					simplifiedMenu={false}
					listHeightRestricted
					style={{
						width: '100%',
						background: '#fafafa',
						marginBottom: '10px',
					}}
				/>
				{renderFormFields()}
			</div>
		)
	}

	const actions: Action[] = [
		{
			id: 'dialog-cancel',
			children: intl.formatMessage({
				defaultMessage: 'Peruuta',
				description: 'Cancel button',
			}),
			onClick: hide,
		},
		{
			id: 'dialog-ok',
			secondary: true,
			children: processing ? (
				<CircularProgress id="progress" style={{ marginTop: 0 }} />
			) : (
				intl.formatMessage({
					defaultMessage: 'Tallenna',
					description: 'Save button',
				})
			),
			onClick: onProcess,
		},
	]

	return (
		<ViiluFullPageDialog
			id="contract-dialog"
			visible
			title={
				id
					? intl.formatMessage({
							defaultMessage: 'Muokkaa sopimusta',
							description: 'Title of edit contract',
					  })
					: intl.formatMessage({
							defaultMessage: 'Lisää sopimus',
							description: 'Title of add contract',
					  })
			}
			toolbarStyle={{
				background: 'var(--color-primary-dark)',
			}}
			nav={
				<Button
					icon
					onClick={() => {
						if (id) {
							const URLParams = new URLSearchParams(window.location.search)
							URLParams.delete('cuuid')
							dispatch(push('?' + URLParams.toString()))
						} else {
							onHide()
						}
					}}
				>
					{id ? 'arrow_back' : 'close'}
				</Button>
			}
			actions={actions}
		>
			<StandardDialogContent>{renderChildren()}</StandardDialogContent>
			{renderPaymentProducts()}
		</ViiluFullPageDialog>
	)
}

export default ContractDialog
